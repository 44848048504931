.container {
    display: inline-block;
    vertical-align: top;
    width: 80px;
}

.labels {
    display: flex;
    justify-content: space-between;
    padding-left: 6px;
    font-size: 10px;
}

.bold {
    font-weight: bold;
}
