.container {
    display: flex;
    height: 60px;
    margin-bottom: 2px;
    margin-top: 2px;
}

.cells {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 2px;
    row-gap: 2px;
}

