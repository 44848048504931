.container {
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 30px;
    max-width: 100%;
}

.label {
    font-weight: bold;
    font-size: 10px;
    position: absolute;
    top: 18px;
    right: 6px;
}

.labelVertical {
    font-weight: bold;
    font-size: 10px;
    position: absolute;
    top: 18px;
    right: 0;
}

.colorRed {
    color: darkred;
}

