.container {
    background-color: lightgrey;
    vertical-align: top;
    margin-top: 2px;
    display: inline-block;
    padding: 2px;
    border-top: 1px;
    border-bottom: 1px;
    border-right: 1px;
    border-left: 0;
    border-color: black;
    border-style: solid;
    width: 92px;
}

.tabs {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 1fr 1fr 1fr;
}

.tabButton {
    font-size: 10px;
    padding-left: 4px;
    padding-right: 4px;
}

.activeTab {
    background-color: #ff8981;
}

.tab {
    padding: 4px;
    position: relative;
}

.container:first-child {
    border-left: 1px;
    border-style: solid;
}

.name {
    margin-top: auto;
    text-align: center;
}


