.container {
    padding: 6px;
    border: 1px solid black;
    background-color: lightgrey;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    align-content: start;
    margin-bottom: 2px;
}

.item {
    margin: 4px;
}
