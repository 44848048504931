body {
    font-family: "Lucida Console", Courier, monospace;
    background-color: #efefef;
}

input {
    font-family: "Lucida Console", Courier, monospace;
}

input[type="number"] {
    border-radius: 0;
    border: 1px solid black;
    padding: 4px 8px;
}

input:focus {
    outline: none;
}

button, button:disabled, button:visited {
    width: max-content;
    display: inline-block;
    font-family: "Lucida Console", Courier, monospace;
    padding: 4px 8px;
    border: 1px solid black;
    border-radius: 0;
}

button {
    background-color: lightgrey;
    color: black;
}

button:disabled {
    background-color: #e5e5e5;
    color: rgba(0, 0, 0, 0.5);
}

button:focus {
    outline: none;
}

