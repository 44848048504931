.container {
    background-color: beige;
    margin-top: 2px;
    position: relative;
    display: inline-block;
    padding: 2px;
    border-top: 1px;
    border-bottom: 1px;
    border-right: 1px;
    border-left: 0;
    border-color: black;
    border-style: solid;
}

.meter {
    position: absolute;
    left: 15px;
    top: 72px;
    display: inline-block;
}


.container:first-child {
    border-left: 1px;
    border-style: solid;
}

.name {
    text-align: center;
}

.volume {
    height: 150px;
    width: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-content: center;
}

.toggleContainer {
    display: inline-grid;
    column-count: 1;
    position: absolute;
    top: 40px;
    left: 10px
}

