.container {
    position: relative;
    vertical-align: top;
    margin: auto;
    height: 100%;
}

.label {
    font-weight: bold;
    font-size: 10px;
    position: absolute;
    top: 10px;
    right: -28px;
}

.colorRed {
    color: darkred;
}

