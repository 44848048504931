.container {
    position: relative;
    margin-top: 2px;
    margin-bottom: 2px;
    border-bottom: 1px solid #9a9a9a;
}

.enableButton {
    position: absolute;
    top: 0;
    right: 5px;
    width: 20px;
}

.sendLabel {
    font-size: 10px;
}

.volumeSlider {
    margin-top: 2px;
    width: 100%;
}
